import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Route, Redirect, BrowserRouter as Router } from "react-router-dom"


export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      routName: false,
      routerUrl: props.match.url
    }
  }

  componentDidMount() {
    window.location.href = "https://www.taylorrobinsonmusic.com/authloads";    
    
  }

  render() {
    return (
      <div className="home-frame">
      </div>
    )
  }
}

