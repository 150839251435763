// export const api_url = "https://www.trmctest.com/vue/AppLogin";
// export const base_url = 'https://www.trmctest.com/vue';
// export const base_url2 = 'https://www.trmctest.com/';
// export const base_url_component = 'https://www.trmctest.com/components/com_front_login/views/commonpack';
// export const base_url_component_student = 'https://www.trmctest.com/components/com_students_login/views/shedulelessons/tmpl';
// export const base_url_component_stud_time = 'https://www.trmctest.com/components/com_students_login/views/commonpack';


export const api_url = "https://www.taylorrobinsonmusic.com/vue/AppLogin";
export const base_url = 'https://www.taylorrobinsonmusic.com/vue';
export const base_url2 = 'https://www.taylorrobinsonmusic.com';
export const base_url_component = 'https://www.taylorrobinsonmusic.com/components/com_front_login/views/commonpack';
export const base_url_component_student = 'https://www.taylorrobinsonmusic.com/components/com_students_login/views/shedulelessons/tmpl';
export const base_url_component_stud_time = 'https://www.taylorrobinsonmusic.com/components/com_students_login/views/commonpack';

export const app_store_url = 'https://taylor-robinson-music-instruments9.mybigcommerce.com/';
export const webcamurl = "https://web.oneclickwebcam.com/taybr999";

export const base_url_live = "https://www.taylorrobinsonmusic.com/";