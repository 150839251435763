import React, { Component } from 'react';
import logo from '../assets/logo_header.png';
import settings from '../assets/setting.png';
import './header.css';
import AuthService from '../services/auth-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import profile_arrow from '../assets/profile_arrow.png';
import setting_iconis from '../assets/setting_iconis.png';
import notification_icon from '../assets/noti_unread.png';
import close from '../assets/close.png';
import * as constVal from '../constant';
import { Button,Modal} from 'react-bootstrap';  



class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
          mobile_menu: false, 
          toggleLesson:  false, 
          togglePayments :  false , 
          toggleResources : false, 
          usertype : null, 
          profile_settings : false , 
          question_setting : false ,
          bussiness_name :'', 
          user_name :'', 
          profile_id :'', 
          user_id :'', 
          show:false , 
          note:'',
          sendLoader : false, 
          notessuccess:'',
          note_error: '', 
          account_name :'', 


        }
      }
    componentDidMount()
    {
        this.setState({usertype : localStorage.getItem('UserType')});
        let logginemail  = localStorage.getItem('LoggedinUser');
        console.log(logginemail, "logginemaillogginemail");
        const params = new URLSearchParams(window.location.search);
        const type = params.get('tp');
        console.log(type, "typetypetype");
        console.log(localStorage.getItem('UserType'), "localstoragelocalstoragelocalstoragelocalstorage");
        if(localStorage.getItem('UserType') == '' || localStorage.getItem('UserType') == null || localStorage.getItem('UserType') == "null" || localStorage.getItem('UserType') == undefined)
        {
            this.setState({usertype : type});
        }
        else 
        {
            this.setState({usertype : localStorage.getItem('UserType')});
        }
        if(type  == 1 || localStorage.getItem('UserType') ==1)
        {
            fetch(constVal.base_url+'/check_availability.php?email='+localStorage.getItem('LoggedinUser')+'&deviceType=web')
            .then(res => res.json())
            .then((responseJson) => {
                console.log(responseJson,'responseJson');
                this.setState({bussiness_name:responseJson.bussiness_name , user_name :responseJson.user_name, profile_id : responseJson.profile_id, user_id: responseJson.user_id });
            }).catch((error) => {
                console.error(error);
            });
        }
        else 
        {
            
            fetch(constVal.base_url+'/chat_student_popup.php?email='+localStorage.getItem('LoggedinUser')+'&deviceType=web')
            .then(res => res.json())
            .then((responseJson) => {
                this.setState({account_name: responseJson.first_name });
            }).catch((error) => {
                console.error(error);
            });
        }
        
    
     }
    
    async logoutPage() {
        await AuthService.logout().then(() => {
            window.location.href = constVal.base_url + "/logout_page_chat.php";
            // window.location.href = constVal.base_url2 + "/logout.html?view=quicklogout";
        })
    }
    mobilemennu = () =>{
        this.setState({mobile_menu :  !(this.state.mobile_menu)});
    }
    toggleLesson = () =>{
        this.setState({toggleLesson :  !(this.state.toggleLesson)});
        this.setState({togglePayments :  false});
        this.setState({toggleResources :  false});
    }
    togglePayments = () =>{
        this.setState({togglePayments :  !(this.state.togglePayments)});
        this.setState({toggleResources :  false});
        this.setState({toggleLesson :  false});
    }
    toggleResources = () =>{
        this.setState({toggleResources :  !(this.state.toggleResources)});
        this.setState({toggleLesson :  false});
        this.setState({togglePayments :  false});
    }
    profile = () =>{
        this.setState({profile_settings :  true, question_setting: false});
    }
    question = () =>{
        this.setState({question_setting :  true, profile_settings: false})
    }
    closeSettingPop= () =>{
        this.setState({profile_settings :  false, question_setting: false})
    }
    handleModal(){  
        this.setState({show:!this.state.show, question_setting : false })  
    }  
    handleSubmitModal()
    {
        if(this.state.note == "")
            this.setState({note_error: "Please enter your information"});
        else 
        {
            this.setState({note_error: "", sendLoader:  true});
            fetch(constVal.base_url+'/submit_support_page.php?notice='+this.state.note+'&emp_id='+localStorage.getItem('LoggedinUser')+'&user_id='+this.state.user_id+'&deviceType=web')
                .then((response) => response.json())
                .then((responseJson) => {
                console.log(responseJson);
                this.setState({sendLoader :false});
                if(responseJson.success ==1)
                {
                    this.setState({notessuccess :"Email sent successfuly"});
                    this.setState({note_error :""});
                }
                else{
                    this.setState({note_error :responseJson.msg});
                    this.setState({notessuccess :""});
                }
                
                
                }).catch((error) => {
                console.error(error);
                this.setState({sendLoader :false});
                });

        }    
    }
    handleChange(event) {
        this.setState({note : event.target.value})
    }
    render() {
        console.log(localStorage.getItem('UserType'));
        return (
            <div className="cc-dashboard-header-section">
                <div className="cc-container">
                    <div className="cc-row">
                        <div className="cc-logo-wrapper">
                            <a href={constVal.base_url2}><img src={logo} alt="homelogo" /></a>
                        </div>

                        {this.state.usertype == 1 ?
                        <div className="cc-row header-cc-sec">
                            <div className= "cc-menu-section">
                                <div className="cc-menu">
                                    <ul>
                                        <li>
                                            <a href={constVal.base_url2 + "/instructor-front-login/homes.html"}>Home</a>
                                        </li>
                                        <li className="cc-submenu-li">
                                            <a href="#"  >Lessons <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /></a>
                                            <ul className="cc-submenu">
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/homes.html"} >Schedule a Lesson</a></li>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/dashboards.html"} >Upcoming Lessons</a></li>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/lessonss.html"} >Previous Lessons</a></li>
                                            </ul>
                                        </li>
                                        <li><a href={constVal.base_url2 +"/instructor-front-login/studentss.html"} >Students</a> </li>
                                        <li className="cc-submenu-li">
                                            <a href="#" >Payments <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /></a>
                                            <ul className="cc-submenu">
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/yourpayments.html"} >Payments</a>li&gt;
                                            </li><li><a href={constVal.base_url2 + "/instructor-front-login/managerbacklinks.html"} >Extras</a></li>
                                            </ul>
                                        </li>
                                        <li className="cc-submenu-li">
                                            <a href="#" >Resources  <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /></a>
                                            <ul className="cc-submenu">
                                                <li><a href={constVal.base_url2 +"/instructor-information-packet"} >Pay &amp; Policies</a></li>
                                                <li><a href={constVal.base_url2 + "/repurchase_report.php"} target="_blank">Repurchase Report</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className = "cc-rightside-menus">
                                <ul>
                                <li className = "cc-settings-wrapper cc-notification" >
                                    <div className="cc-notification-acc"> 
                                        <img src={notification_icon} alt="notification"/>
                                    </div>
                                </li>
                                <li className = "cc-settings-wrapper cc-sw" >
                                    <div className="cc-settings-acc"> 
                                        <img src={settings} alt="settings" onClick={this.profile}/>
                                        <div className = {`cc-setting-options ${this.state.profile_settings == true ? "show" :  ''}` } >
                                            <div className = "cc-section-content">
                                                <div className = "cc-arrow-section">
                                                    <img src={profile_arrow} alt="arrow" />
                                                </div>
                                                <div className = "cc-title">
                                                <p className="cc-user-name">Welcome <span> {this.state.user_name}<span></span></span></p>
                                                <p className="cc-user-name"> Business name: <span>{this.state.bussiness_name}</span></p> 
                                                </div>
                                                <div className = "cc-account">
                                                    <a href={constVal.base_url2 +"/instructor-front-login/settingedits.html"}>
                                                        <span className = "cc-account-img"></span><span style={{color:"#337ab7 ",fontSize: "15px"}} className = "cc-link-label">Account</span>
                                                    </a>
                                                </div>
                                                
                                                <div className = "cc-pro-setting">
                                                    <a href={constVal.base_url2 +"/instructor-front-login/profileedits.html"}>
                                                    <img src={setting_iconis} alt="settings" />
                                                    <span className = "cc-link-label" style={{fontSize:"15px"}}>Profile and settings</span>
                                                    </a>
                                                </div>
                                                <div className = "cc-close-gear-popup" onClick={this.closeSettingPop}>
                                                    <img src={close} alt="closepopup" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className = "cc-settings-wrapper cc-question-wrapper" style={{paddingLeft:13}} >
                                    <div> 
                                        <div className = "cc-faquestion" onClick={this.question}>
                                            <FontAwesomeIcon icon={faQuestionCircle} color="#5495de" size= {28} style={{fontSize: 24}} />
                                            {/* <i className = "fa fa-question-circle" aria-hidden="true"></i> */}
                                        </div>
                                        <div className = {`cc-setting-options cc-profilemenu-options ${this.state.question_setting == true ? "show" :  ''}` }>
                                            <div className = "cc-section-content section-content-profile">

                                                <div className = "cc-arrow-section arrow-section-profile">
                                                    <img src={profile_arrow} alt="arrow" />
                                                </div>

                                                <div className = "cc-title" style={{borderBottom:0}}>
                                                </div>

                                                <div className = "cc-account">
                                                    <a href={constVal.base_url2 +"/instructors/"+this.state.profile_id+".html"} target="_blank">
                                                        <FontAwesomeIcon icon={faUser}  style={{fontSize :  22, marginRight: 10, color:"#337ab7"}} /><span style={{fontSize:"16px",color:"#337ab7"}} className = "cc-link-label">View my profile</span>
                                                    </a>
                                                </div>

                                                <div className = "cc-pro-setting cc-support-setting">
                                                    <a style= {{cursor: "pointer"}}  onClick={()=>this.handleModal()}>
                                                        <FontAwesomeIcon icon={faLifeRing}  style= {{fontSize: 18, marginRight:4, color:"#337ab7"}} />
                                                        <span style={{fontSize:"15px"}} className = "cc-link-label">Support</span>
                                                    </a>
                                                </div>

                                                <div className = "cc-pro-setting" style= {{paddingBottom: 15, borderBottom:0}}>
                                                    <a href={constVal.base_url2 +"/index.php?option=com_contact&view=contact&id=1"}>
                                                         <FontAwesomeIcon icon={faEnvelope}  style= {{fontSize: 18, marginRight:4, color:"#337ab7"}} />
                                                        <span style={{fontSize:"15px"}} className = "cc-link-label">Contact us</span>
                                                    </a>
                                                </div>

                                                <div className = "cc-close-gear-popup"  onClick={this.closeSettingPop}>
                                                    <img src={close} alt="closepopup" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                </ul>
                            </div>
                           
                        </div>
                            :
                            this.state.usertype == 2 ?
                                <div className="cc-row">
                                    <div className="cc-menu-section">
                                        <div className="cc-menu">
                                            <ul>
                                                <li>
                                                    <a href={constVal.base_url2 + "/studentslogin/homes.html"}>Home</a>
                                                </li>
                                                <li className="cc-submenu-li">
                                                    <a href="#">Schedule  <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /> </a>
                                                    <ul className="cc-submenu">
                                                        <li><a href={constVal.base_url2 + "/studentslogin/homes.html"}>Schedule a Lesson</a></li>
                                                        <li><a href={constVal.base_url2 + "/studentslogin/upcominglessons.html"}>Upcoming Lessons</a></li>
                                                        <li><a href={constVal.base_url2 + "/studentslogin/lessonhistorys.html?schpage=2"}>Previous Lessons</a></li>
                                                    </ul>
                                                </li>
                                                <li> <a href={constVal.base_url2 + "/studentslogin/packages.html"}>Balance</a> </li>
                                                <li> <a href={constVal.base_url2 + "/studentslogin/rev.html"}>Reviews</a> </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className = "cc-rightside-menus">
                                    <ul>
                                    <li className = "cc-settings-wrapper cc-sw" >
                                        <div className="cc-settings-acc"> 
                                            <img src={settings} alt="settings" onClick={this.profile}/>
                                            <div className = {`cc-setting-options ${this.state.profile_settings == true ? "show" :  ''}` } >
                                                <div className = "cc-section-content">
                                                    <div className = "cc-arrow-section">
                                                        <img src={profile_arrow} alt="arrow" />
                                                    </div>
                                                    <div className = "cc-title">
                                                    <p>Welcome </p>
                                                    <p> Account Name: <span>{this.state.account_name}</span></p> 
                                                    </div>
                                                    <div className = "cc-account">
                                                        <a href={constVal.base_url2 +"/studentslogin/accountinformations.html"}>
                                                            <span className = "cc-account-img"></span><span style={{color:"#337ab7"}} className = "cc-link-label">Account</span>
                                                        </a>
                                                    </div>
                                                    <div className = "cc-pro-setting">
                                                        <a href={constVal.base_url2 +"/studentslogin/notification.html"}>
                                                        <img src={setting_iconis} alt="settings" />
                                                        <span className = "cc-link-label">Notifications</span>
                                                        </a>
                                                    </div>
                                                    <div className = "cc-close-gear-popup" onClick={this.closeSettingPop}>
                                                        <img src={close} alt="closepopup" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className = "cc-settings-wrapper cc-question-wrapper" style={{paddingLeft:20}} >
                                        <div> 
                                            <div className = "cc-faquestion" onClick={this.question}>
                                                <FontAwesomeIcon icon={faQuestionCircle} color="#5495de" size= {28} style={{fontSize: 28}} />
                                                {/* <i className = "fa fa-question-circle" aria-hidden="true"></i> */}
                                            </div>
                                            <div className = {`cc-setting-options cc-profilemenu-options ${this.state.question_setting == true ? "show" :  ''}` }>
                                                <div className = "cc-section-content">

                                                    <div className = "cc-arrow-section">
                                                        <img src={profile_arrow} alt="arrow" />
                                                    </div>

                                                    <div className = "cc-title" style={{borderBottom:0}}>
                                                    </div>
                                                    <div className = "cc-pro-setting cc-support-setting">
                                                        <a style= {{cursor: "pointer"}}  onClick={()=>this.handleModal()}>
                                                            <FontAwesomeIcon icon={faLifeRing}  style= {{fontSize: 18, marginRight:4, color:"#337ab7"}} />
                                                            <span className = "cc-link-label">Support</span>
                                                        </a>
                                                    </div>

                                                    <div className = "cc-pro-setting" style= {{paddingBottom: 15, borderBottom:0}}>
                                                        <a href={constVal.base_url2 +"/index.php?option=com_contact&amp;view=contact&amp;id=1"}>
                                                            <FontAwesomeIcon icon={faEnvelope}  style= {{fontSize: 18, marginRight:4, color:"#337ab7"}} />
                                                            <span className = "cc-link-label">Contact us</span>
                                                        </a>
                                                    </div>

                                                    <div className = "cc-close-gear-popup"  onClick={this.closeSettingPop}>
                                                        <img src={close} alt="closepopup" />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                                    
                                </div>
                                :
                                null}
                                     <div className="cc-rightside-menus1">
                                        <ul>
                                            <li className="cc-hide-mobile cc-logoutbtn">
                                                <a href="#" onClick={this.logoutPage} >Log out</a>
                                            </li>
                                        </ul>
                                        <ul>
                                        <li className="cc-show-mobile" onClick={this.mobilemennu}>
                                            <div className="cc-menubar">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>
                                    </ul>
                           
                        </div>
                        {/* 
                        <li className="cc-hide-mobile cc-logoutbtn">
                            <a href="/logout.html?view=quicklogout" onclick="logoutpage()">Log out</a>
                        </li> */}
                    </div>
                </div>



                        <div className={ this.state.mobile_menu == true && this.state.usertype == 1?  "cc-mobile-menu-section inside" : "cc-mobile-menu-section"} style={{}}>
                                <div className="cc-mobile-menu">
                                    <ul>
                                        <li>
                                            <a href={constVal.base_url2 + "/instructor-front-login/homes.html"}>Home</a>
                                        </li>
                                        <li className="cc-mobile-submenu-li " onClick={this.toggleLesson}>
                                            <a href="#"  >Lessons <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /></a>
                                            <ul className="cc-mobile-submenu" style={{display:  this.state.toggleLesson == true ? "block" :  "none"}}>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/homes.html"} >Schedule a Lesson</a></li>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/dashboards.html"} >Upcoming Lessons</a></li>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/lessonss.html"} >Previous Lessons</a></li>
                                            </ul>
                                        </li>
                                        <li><a href={constVal.base_url2 + "/instructor-front-login/studentss.html"}>Students</a> </li>
                                        <li className="cc-mobile-submenu-li" onClick={this.togglePayments}>
                                            <a href="#" >Payments <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /></a>
                                            <ul className="cc-mobile-submenu" style={{display:  this.state.togglePayments == true ? "block" :  "none"}}>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/yourpayments.html"} >Payments</a></li>
                                                <li><a href={constVal.base_url2 + "/instructor-front-login/managerbacklinks.html"} >Extras</a></li>
                                            </ul>
                                        </li>
                                        <li className="cc-mobile-submenu-li" onClick={this.toggleResources}>
                                            <a href="#" >Resources  <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /></a>
                                            <ul className="cc-mobile-submenu" style={{display:  this.state.toggleResources == true ? "block" :  "none"}}>
                                                <li><a href={constVal.base_url2 +"/instructor-information-packet"} >Pay &amp; Policies</a></li>
                                                <li><a href={constVal.base_url2 + "/repurchase_report.php"} target="_blank">Repurchase Report</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#" onClick={this.logoutPage} >Log out</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={ this.state.mobile_menu == true && this.state.usertype == 2?  "cc-mobile-menu-section inside" : "cc-mobile-menu-section"} style={{}}>
                                <div className="cc-mobile-menu">
                                    <ul>

                                        <li>
                                            <a href={constVal.base_url2 + "/studentslogin/homes.html"}>Home</a>
                                        </li>
                                        <li className="cc-mobile-submenu-li" onClick={this.toggleLesson}>
                                            <a href="#">Schedule  <FontAwesomeIcon icon={faAngleDown} color={'#363838'} /> </a>
                                            <ul className="cc-mobile-submenu" style={{display:  this.state.toggleLesson == true ? "block" :  "none"}}>
                                                <li><a href={constVal.base_url2 + "/studentslogin/homes.html"}>Schedule a Lesson</a></li>
                                                <li><a href={constVal.base_url2 + "/studentslogin/upcominglessons.html"}>Upcoming Lessons</a></li>
                                                <li><a href={constVal.base_url2 + "/studentslogin/lessonhistorys.html?schpage=2"}>Previous Lessons</a></li>
                                            </ul>
                                        </li>
                                        <li> <a href={constVal.base_url2 + "/studentslogin/packages.html"}>Balance</a> </li>
                                        <li> <a href={constVal.base_url2 + "/studentslogin/rev.html"}>Reviews</a> </li>
                                        <li>
                                            <a href="#" onClick={this.logoutPage} >Log out</a>
                                        </li>               
                                    </ul>
                                </div>
                            </div>


                            <Modal show={this.state.show} onHide={()=>this.handleModal()}>  
                    <Modal.Header >
                        <h4 className= "popup_header">
                            Submit your question or concern here. We’ll get back to you within one business day at most.
                        </h4>
                    </Modal.Header>  
                    <Modal.Body>
                    <textarea id="textareaSupportMenu" name="textareaSupportMenu" onChange={this.handleChange.bind(this)}  value={this.state.note}></textarea>
                    {this.state.note_error != '' ? 
                        <div id="err_notice_support" name="err_notice_support" style={{color: '#ff4d4d'}}>{this.state.note_error}</div>
                    : null }
                    
                    </Modal.Body>  
                    <Modal.Footer>  
                        {this.state.notessuccess != '' ? 
                            <div id="success_notice_support" name="success_notice_support" style={{color: '#219821', textAlign:'center', width:'100%',fontSize:"14px"}}>{this.state.notessuccess}</div>
                        : null }
                        <Button className ={`popup_buttons ${this.state.sendLoader == true ? "smallloadergif" :  null}`} onClick={()=>this.handleSubmitModal()}>Submit</Button>  
                        <Button className ="popup_buttons"  onClick={()=>this.handleModal()}>Close</Button>  
                    </Modal.Footer>  
                    </Modal>  


            </div>
        )
    }
}

export default Header;
