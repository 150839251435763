import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import './userProfile.css'
import AuthService from '../../../../services/auth-service'
import Modal from 'react-modal'
import { Link } from "react-router-dom"
import Avatar from '../../../../helpers/avatar/avatar'
import logo from '../../../../assets/logo.png'
import ChatService from '../../../../services/chat-service'
import * as constVal from '../../../../constant';


class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModal: false,
      usernames:'',
      userlistget : false,
    }
  }

  customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      padding: 0,
     
    }
  }

  showMoreInformation = () => {
    this.setState({ isModal: !this.state.isModal })
  }
  goBack() {
    window.history.back();
  }
  handleCloseModal = () => (this.setState({ isModal: false }))

  logOut = () => {
    AuthService.logout()
  }

  settings = () => {
    alert("Coming soon")
    this.handleCloseModal()
  }
  async getUsersList(userid)
  {
    this.setState({userlistget : true});
     await ChatService.getUsersList(userid)
     .then((response) => {
       this.setState({usernames : response[0].full_name});
    });
   
  }
  render() {
    const { isModal } = this.state
    const { currentUser } = this.props
    if (!currentUser) {
      return <></>
    }
    const { user } = currentUser
    if(this.state.userlistget == false)
    {
     const { userlist }   = this.getUsersList(currentUser.user_id);
    }
    return (
      <div className="user-rofile-container">
        {/* <div className="user-profile-wrapper" style={{paddingBottom:0, backgroundColor:"white", paddingTop:0, paddingLeft:10, paddingRight:10}}>
          <div className="user-profile-user-info">
            <div className="online" style={{border:0, paddingRight:10, cursor:"pointer"}} onClick={this.goBack}>
                <FontAwesomeIcon icon={faArrowLeft} color={'black'} /> 
            </div>
            <a href={constVal.base_url2} id="bottle" >
               <img src={logo} alt="Logo" style={{paddingLeft:10,  width: "85%", cursor:"pointer"}} />
            </a>
          </div>
        </div> */}
        <div className="user-profile-wrapper" style={{paddingLeft:10, paddingRight:5, paddingTop:10, paddingBottom:10}}>
          <div className="user-profile-user-info">
            <div className="online">
              <Avatar photo={user.avatar} name={this.state.usernames == '' || this.state.usernames == null || this.state.usernames == undefined ? user.full_name : this.state.usernames} size={50} />
            </div>
            <span>{this.state.usernames}</span>
          </div>
          {/* <div className="user-profile-icon" id="user-profile-icon" onClick={this.showMoreInformation}>
            {isModal ?
              <FontAwesomeIcon icon={faChevronUp} color={'white'} /> :
              <FontAwesomeIcon icon={faChevronDown} color={'#435f7a'} />
            }
          </div> */}
        </div>
        
        {isModal &&
          <>
            <Modal
              isOpen={isModal}
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              style={this.customStyles}
              overlayClassName="Overlay-user-profile"
            />
            {/* <div className="user-profile-modal">
              <ul>
                <li className="user-profile-user-more-info">
                  <Link
                    to="/auth"
                    onClick={this.logOut}
                    className="reset-user-profile">Logout</Link>
                </li>
                <li className="user-profile-user-more-info">
                  <Link
                    to="/home"
                    onClick={this.settings}
                    className="reset-user-profile">Settings</Link>
                </li>
              </ul>
            </div> */}
          </>
        }
      </div>
    )
  }
}


const mapStateToProps = ({ currentUser }) => ({
  currentUser
})

export default connect(mapStateToProps)(UserProfile)
