import React, { Component } from 'react';
import logo from '../assets/logo-login.png';
import './footer.css'
import * as constVal from '../constant';


class Footer extends Component {

    render() {
        return(
            <footer className="footer_area" >
            <div className="footer_top">
               <div className="container">
                  <div className="row">
                     <article className="moduletable col-sm-3 footer_widget" id="module-199">
                        <h4 className="module-title">Main menu</h4>
                        <section className="module-content">
                           <ul className="menu">
                              <li className="item-285"><a href={constVal.base_url2}>Home</a></li>
                              <li className="item-286"><a href={constVal.base_url2+"/about-us.html"}>About Us</a></li>
                              <li className="item-3778"><a href={constVal.base_url2+"/blog"}>Blog</a></li>
                              <li className="item-288"><a href={constVal.base_url2+"/faq2.html"}>FAQ</a></li>
                              <li className="item-290"><a href={constVal.base_url2+"/contact-us.html"}>Contact Us</a></li>
                              <li className="item-426"><a href={constVal.base_url2+"/refund-and-cancellation-policy.html"}>Refund and Cancellation Policy</a></li>
                           </ul>
                        </section>
                     </article>
                     <article className="moduletable col-sm-3 footer_widget" id="module-157">
                        <h4 className="module-title">Lessons</h4>
                        <section className="module-content">
                           <ul className="menu">
                              <li className="item-266"><a href={constVal.base_url2+"/instructors/guitar-lessons.html"}>Guitar Lessons</a></li>
                              <li className="item-267"><a href={constVal.base_url2+"/instructors/bass-guitar-lessons.html"}>Bass Guitar Lessons</a></li>
                              <li className="item-268"><a href={constVal.base_url2+"/instructors/drum-lessons.html"}>Drum Lessons</a></li>
                              <li className="item-269"><a href={constVal.base_url2+"/instructors/vocal-lessons.html"}>Vocal &amp; Singing Lessons</a></li>
                              <li className="item-270"><a href={constVal.base_url2+"/instructors/keyboard-and-piano-lessons.html"}>Keyboard &amp; Piano Lessons</a></li>
                              <li className="item-271"><a href={constVal.base_url2+"/instructors/strings-brass-woodwinds.html"}>Strings Brass and Woodwinds</a></li>
                              <li className="item-273"><a href={constVal.base_url2+"/instructors/song-writing-lesson.html"}>Song Writing Lessons</a></li>
                           </ul>
                        </section>
                     </article>
                     <article className="moduletable col-sm-3 footer_widget" id="module-159">
                        <h4 className="module-title">Tools</h4>
                        <section className="module-content">
                           <ul className="menu">
                              <li className="item-318"><a href={constVal.base_url2+"/one-click-webcam-classroom.html"} target="_blank">Webcam Beta - Use Chrome</a></li>
                           </ul>
                        </section>
                     </article>
                     <article className="moduletable col-sm-3 footer_widget" id="module-158">
                        <h4 className="module-title">About Us</h4>
                        <section className="module-content">
                           <ul className="menu">
                              <li className="item-249"><a href={constVal.base_url2+"/meet-the-staff.html"}>Meet the Staff</a></li>
                              <li className="item-250"><a href={constVal.base_url2+"/trmc-mascots.html"}>TRMC Mascots</a></li>
                              <li className="item-251"><a href={constVal.base_url2+"/about-us/faq.html"}>FAQ</a></li>
                           </ul>
                        </section>
                     </article>
                  </div>
               </div>
            </div>
            <div className="footer_bottom">
               <div className="container">
                  <div className="row">
                     <div className="footer_bottom_text">
                        <article className="moduletable_info" id="module-162">
                           <section className="module-content">
                              <div className="custom_info">
                                 <p className="text-left leftContent">
                                     <a href="mailto:%43O%4D%50AN%59%40T%41%59LORROBI%4ESONMU%53IC.COM" rel="nofollow" style={{textDecoration:"none"}}>COMPANY@<span className="custom-hide"></span>TAYLORROBINSONMUSIC.COM  </a> 
                                     <br />ALL RIGHT RESERVED
                                </p>
                                </div> 
                                <img className="spopd_sdisplay" src="https://www.facebook.com/tr?id=579694612464023&amp;ev=PageView&amp;noscript=1" alt="Facebook Pixel Code" width="1" height="1" />
                           </section>
                        </article>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
        )
    }
}

export default Footer;