import React, { Component } from 'react';
import { getTime } from '../../../../helpers/lastDate'
import Avatar from '../../../../helpers/avatar/avatar'
import { GetMaxWidthMsg } from '../../../../helpers/LayoutUtil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import MessageSendState from './messageStatus'
import Modal from 'react-modal'
import './message.css'
import Viewer from 'react-viewer';
import AutolinkerWrapper from 'react-autolinker-wrapper'

export default class Message extends Component {
  // 1 - current & 2 - other
  constructor(props) {
    super(props)
    this.state = {
      isModal: false
    }
  }

  selectedImg = null

  customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      padding: 0,
      visible : false, 
    }
  }
  _renderAsStrTime = (whoIsSender) => {
    const { message } = this.props
    // if (whoIsSender === 1) {
      return getTime(message.date_sent)
    // }
  }
  _renderAsStrState = (whoIsSender) => {
    const { message } = this.props
    // if (whoIsSender === 1) {
      return message.send_state
    // }
  }
  _renderAsStrmessage = (whoIsSender) => {
    const { message } = this.props
    // if (whoIsSender === 1) {
      return message.body
    // }
  }

  _renderAsStr = (whoIsSender) => {
    const { message } = this.props
    if (whoIsSender === 1) {
      return message.body+'*--*'+getTime(message.date_sent)+'*--*'+message.send_state
      // (
        // <>
        // <AutolinkerWrapper
        //   tagName="span"
        //   text={message.body}
        //   options={{
        //     newWindow: true,
        //     stripPrefix: false,
        //   }} />
        //   {/* <span style={{ wordWrap: 'break-word' }}>{message.body}</span> */}
        //   <div className="chat-message-right-footer">
        //     <span>
        //       <>
        //         {getTime(message.date_sent)}
        //       </>
        //       <>
        //         {<MessageSendState send_state={message.send_state} />}
        //       </>
        //     </span>
        //   </div>
        // </>
      // )
    } else {
      return message.body
      // (
      //   <>
      //    <AutolinkerWrapper
      //     tagName="span"
      //     text={message.body}
      //     options={{
      //       newWindow: true,
      //       stripPrefix: false,
      //     }} />
      //     {/* <span style={{ wordWrap: 'break-word' }}>{message.body}</span> */}
      //     <div className="chat-message-left-footer">
      //       <span>{getTime(message.date_sent)}</span>
      //     </div>
      //   </>
      // )
    }
  }

  renderZoomImg = (event, message) => {
    event.preventDefault()
    this.selectedImg = message.attachment[0]
    this.setState({ visible: true })
  }

  handleCloseModal = () => this.setState({ isModal: false })

  _renderAsAttachment = () => {
    const { message, whoIsSender } = this.props
    return (
      <>
        <div className="chat-message-container-attachment">
          <div style={{
            backgroundImage: `url(${message.attachment[0].url})`,
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            border: '1px solid #cbcbcb',
            cursor: 'pointer'
          }}
            onClick={(e) => this.renderZoomImg(e, message)}
          />
        </div>
        {whoIsSender === 1 ?
          <div className="chat-message-right-footer">
            <span>
              <>
                {getTime(message.date_sent)}
              </>
              <>
                {<MessageSendState send_state={message.send_state} />}
              </>
            </span>
          </div> :
          <div className="chat-message-left-footer">
            <span>{getTime(message.date_sent)}</span>
          </div>
        }
      </>
    )
  }

  render() {
    const { message, whoIsSender, participantInfo, notRenderAvatar, widthScroll } = this.props
    const { visible } = this.state
    const withMsg = new GetMaxWidthMsg(widthScroll)
    var  time = message.attachment ? this._renderAsAttachment(1) : this._renderAsStrTime(1);
    var status = message.attachment ? this._renderAsAttachment(1) : this._renderAsStrState(1);
    var orgismg = message.attachment ? this._renderAsAttachment(1) : this._renderAsStrmessage(1);
    return (
      <>
        {/* {isModal &&
          <Modal
            isOpen={isModal}
            onRequestClose={this.handleCloseModal}
            ariaHideApp={false}
            style={this.customStyles}
            overlayClassName="overlay-chat-attachment"
          >
            <div className="active-window-modal-attachment">
              <FontAwesomeIcon icon={faTimesCircle} color={'white'} onClick={this.handleCloseModal} />
              <img
                src={this.selectedImg.url}
                width={600}
                height={600}
                alt="zoomImg"
              />
            </div>
          </Modal>
        } */}
        {visible &&
              <Viewer
              visible={this.state.visible}
              onClose={() => {this.setState({ visible: false })} }
              images={[{src: this.selectedImg.url, alt: ''}]}
              />
        }
        <div className="chat-message-layout">
        <div  />
          {whoIsSender === 1 ?
            <div className="chat-message-wrap chat-message-wrap-right" style={{width: "inherit", paddingRight:15}}>
               <div style={{ maxWidth: `${withMsg.currentSender}px` }} className="chat-message-container-position-right"  >
                   <span style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{__html: orgismg}}></span>
                    <div className="chat-message-right-footer">
                         <span>
                          <>
                            {time}
                          </>
                          <>
                            {<MessageSendState send_state={status} />}
                          </>
                        </span>
                      </div>
          
             
              </div>
            </div> :
            <div className="chat-message-wrap chat-message-wrap-left" style={{width: "inherit"}}>
              <div className="chat-message-avatar" style={{width:0}}>
                {/* {notRenderAvatar &&
                  <Avatar photo={participantInfo.avatar} name={participantInfo.name || participantInfo.full_name} size={30} />
                } */}
              </div>
              <div style={{ maxWidth: `${message.attachment ? withMsg.otherSender + 60 : withMsg.otherSender}px` }} className="chat-message-container-position-left">
              <span style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{__html: orgismg}}></span>
              <div className="chat-message-left-footer">
                <span>{time}</span>
              </div>
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}

