import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import ImagePicker from '../../../../helpers/imagePicker/imagePicker'
import './chatInput.css'

export default class ChatInput extends PureComponent {
  state = {
    messageText: '',
    isLoader: false,
  }

  changeMessage = event => (this.setState({ messageText: event.target.value }))

  sendMessage = (e) => {
    e.preventDefault();
    this.setState({isLoader:  true});
    this.props.sendMessageCallback(this.state.messageText)
      .then(() => (this.setState({ messageText: '', isLoader:  false  })))
      .catch((e) => (
        this.setState({ messageText: '', isLoader:  false })
      ))
  }

  getImage = (image) => {
    this.props.sendMessageCallback(this.state.messageText, image)
      .then(() => (this.setState({ messageText: '' })))
      .catch(() => (this.setState({ messageText: '' })))
  }

  render() {
    const { messageText } = this.state;
    let imgHeights = {};

    let img = new Image();
     img.src = "https://api.connectycube.com/blobs/5fab8166adb94f9c82660585c22af16f00?token=2cb35ba9d24153e5a56af70ef2dfc1aaca000da8";
     img.onload = () => {console.log(img.height, "img.height;img.height;img.height;");}


    return (
      <footer>
        <form onSubmit={this.sendMessage} style={{maxWidth: "100%", marginTop:10, height: window.innerWidth < 768 ?50 : 60 }}>
          <input
            type="text"
            value={messageText}
            onChange={this.changeMessage}
            autoComplete="off"
            style={{paddingRight: 15}}
            placeholder="Write your message..."
            name="search" />
          <div className="chat-attachment">
            <ImagePicker pickAsAttachment getImage={this.getImage} />
          </div>
          {this.state.isLoader == true ? 
            <button disabled=""><div className ="loader" style={{ marginLeft: 10}}></div></button>
           : 
               <button onClick={this.sendMessage} style={{marginTop: window.innerWidth < 768 ? 5: 3, height:window.innerWidth < 768 ? 45: 57}}>
                  <FontAwesomeIcon icon={faPaperPlane} style={{fontSize: window.innerWidth < 768 ? 22 : 25}}  color={'white'} />
              </button>
          }
        </form>
      </footer>
    );
  }
}
