import React, { Component } from 'react'
import AuthService from '../../services/auth-service'
import logo from '../../assets/logo_with_text.png'
import swal from 'sweetalert'
import './auth.css'
import Loader from '../../helpers/loader/loader'
import { Redirect } from "react-router-dom"
import * as constVal from '../../constant';
import ConnectyCube from 'connectycube'
import store from '../../store';
import { LogOut } from '../../reducers/index'

export default class Auth extends Component {
  state = {
    isLogin: true,
    isLoader: false,
    full_name: '',
    login: '',
    password: '',
    isAuthorization: false
  }

  handleStateModal = () => {
    return this.setState(
      { isLogin: !this.state.isLogin }
    )
  }

  changeName = (event) => (this.setState({ full_name: event.target.value }))

  changeLogin = (event) => (this.setState({ login: event.target.value }))

  changePassword = (event) => (this.setState({ password: event.target.value }))

  async componentDidMount() {

    
    this.setState({ isLoader: true });
   
    const params = new URLSearchParams(this.props.location.search);
    var user = params.get('user'); 
    const type = params.get('tp');
    var  id = params.get('selected');
    localStorage.clear();
    user = atob(user);
    var logout = params.get('logout');
    // if(id && id !== ''  && id !== null && id !== undefined)
    // {
    //   id = atob(id);
    //     }
    localStorage.setItem('UserType', type);
    if(logout == true)
    {
      await AuthService.logout().then(() => {
        window.close();
      })
      
    }
    else if(user !== null && user !== 'null' && user !== ' '&& user !== '' && user !== undefined)
    {
      this.login(user, type, id);
    }
    else if(window.location.href == 'https://chat.taylorrobinsonmusic.com/authloads')
    {
      window.location.href = "https://www.taylorrobinsonmusic.com/authloads";
    }
    else 
    {
      window.location.href=constVal.base_url2+"/login-form.html";
      // window.close();
    }
    
   
    
    // AuthService.logout().then(() => {// bar
      
    // })
    // .catch(error => {
    //   console.log("login false")
    //   this.setState({ isLoader: false })
    // })

  }
  login = (user, type, id) => {
    // e.preventDefault()
    localStorage.setItem('selected', id);
    localStorage.setItem('LoggedinUser', user);
    localStorage.setItem('UserType', type);
    const { login, password, full_name, isLogin } = this.state
    const dataUser = { login : user , password: "Web$peed@123" }
    var user ='';
      AuthService.signIn(dataUser)
        .then(() => {
          console.log("login done")
          this.setState({ isLoader: false, isAuthorization: true })
        })
        .catch(error => {
          window.location.href=constVal.base_url2+"/login-form.html";
          console.log("login false")
          this.setState({ isLoader: false })
        })

  }

  render() {
    const { isLogin, login, password, isLoader, isAuthorization, full_name } = this.state
    const authText = isLogin ? "Don't have an account?" : 'Already have an account?'
    const authLink = isLogin ? 'Sign up' : 'Sign in'

    return (
      <div className="auth-main-сontainer" style={this.props.isSmallDevice && { backgroundColor: '#29ace2' }}>
        <div style={isLogin ? { height: '500px' } : { height: '570px' }}>
          {isLoader &&
            <div className="auth-wrapp-loader">
              <Loader />
            </div>
          }
          {isAuthorization &&
            <Redirect to="/home"  />
          }
        </div>
      </div >
    )
  }
}

