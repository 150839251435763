import ConnectyCube from 'connectycube'
import Dialog from '../models/dialogs'
import profile_photo_static from '../assets/stillhoute.png';
import {
  fetchDialogs,
  sortDialogs,
  updateDialog,
  addNewDialog,
} from '../actions/dialogs'
import {
  pushMessage,
  fetchMessages,
  lazyFetchMessages,
  updateMessages,
} from '../actions/messages'
import { preparationAttachment } from '../helpers/file'
import { selectedDialog } from '../actions/selectedDialog'
import { fetchUsers } from '../actions/users'
import store from '../store'
import { Message, FakeMessage } from '../models/message'
import UserModel from '../models/user'
import { DIALOG_TYPE } from '../helpers/constants'
import * as constVal from '../constant';
import {
  STATUS_DELIVERED,
  STATUS_READ,
  STATUS_SENT,
  GROUP_CHAT_ALERT_TYPE
} from '../models/message'

class ChatService {

  setUpListeners() {
    ConnectyCube.chat.onMessageListener = this.onMessageListener.bind(this)
    ConnectyCube.chat.onSentMessageCallback = this.onSentMessageListener.bind(this)
    ConnectyCube.chat.onDeliveredStatusListener = this.onDeliveredStatus.bind(this)
    ConnectyCube.chat.onReadStatusListener = this.onReadStatus.bind(this)
  }

  async fetchDialogsFromServer() {
    if (store.getState().dialogs.length !== 0) {
      return store.getState().dialogs
    }
    
    let email = localStorage.getItem('LoggedinUser');
    console.log(this.currentUser, "currentUsercurrentUsercurrentUser")
    const currentUserId = this.currentUser
    if(email.toLowerCase() !== currentUserId.login.toLowerCase())
    {
      window.location.reload();
    }
    let dialogsFromServer = await ConnectyCube.chat.dialog.list();

    let selected = localStorage.getItem('selected');
    let check_dialog_exist=0;
    console.log(selected,"selectedselected")
    var d=0;
    if(selected !== null && selected !== 'null' && selected !== ' '&& selected !== '' && selected !== undefined){
      for(var i=0;i<dialogsFromServer.items.length;i++)
      {
        let get_dialog_id=dialogsFromServer.items[i]._id;
        
        console.log(get_dialog_id,'get_dialog_idservice');
          if(get_dialog_id == selected && check_dialog_exist!=1)
          {
            check_dialog_exist=1;
          }

          console.log(dialogsFromServer.items[i]._id,'idss1');

      }
   }

   if(selected !== null && selected !== 'null' && selected !== ' '&& selected !== '' && selected !== undefined && check_dialog_exist==0){

    const filter_dialog = {
      _id: selected,
    }
    console.log(filter_dialog,'filter_dialog');
    let fetch_missing_dialog = await ConnectyCube.chat.dialog.list(filter_dialog);
    console.log(fetch_missing_dialog,'fetch_missing_dialog');

    if(fetch_missing_dialog!='')
    {
      dialogsFromServer.items.push(fetch_missing_dialog.items[0]);
    }

 }

 console.log(dialogsFromServer,"dialogsFromServer22222");

    await Promise.all(dialogsFromServer.items.map( async (elem1, index) => {
      await  ConnectyCube.chat.message.list({
         chat_dialog_id: elem1._id,
             sort_desc: 'created_at',
             limit: 1,
           }).then(async (response) => {
               if(response.items.length>0 && response.items[0].message !=='' && response.items[0].message!== null && response.items[0].message!== undefined)
               {
                let dialog_userid =  dialogsFromServer.items[index].occupants_ids.find(elem => elem != currentUserId.id);
                let dialog_userlogin = '';
                await this.getUsersList(dialog_userid)
                .then((response) => {
                    
                    console.log(JSON.stringify(response),"response74859311");
                    if(response!== undefined && response!='')
                    {
                        dialog_userlogin = response[0].login;
                    }    
                });
                if(dialog_userlogin!== '')
                {
                    let profile_photo= '';
                    let profile_name= '';
                    await fetch(constVal.base_url+'/get_profile.php?dialog_userlogin='+dialog_userlogin+"&chat=1")
                    .then(res => res.json())
                    .then((responseJson) => {
                        if(responseJson != '' && responseJson != null)
                        profile_photo = constVal.base_url2+'/'+responseJson.photo;
                        profile_name =responseJson.name;
                    }).catch((error) => {
                        console.error(error);
                    });
                    if(profile_photo == '')  
                    profile_photo = profile_photo_static;
                    
                    if(profile_name)
                    {
                    dialogsFromServer.items[index].name  =profile_name; 
                    }

                    dialogsFromServer.items[index].last_message = response.items[0].message.replace(/<(.|\n)*?>/g, ' '); 
                    dialogsFromServer.items[index].photo = profile_photo;
                }   
               }
           });
    }), );


      
    let privatChatIdsUser = []

    const dialogs = dialogsFromServer.items.map(elem => {
      if (elem.type === DIALOG_TYPE.PRIVATE) {
        elem.occupants_ids.forEach(elem => {
          elem !== currentUserId.id && privatChatIdsUser.push(elem)
        })
      }
      return new Dialog(elem)
    })

    if (privatChatIdsUser.length !== 0) {
      const usersInfo = await this.getUsersList(privatChatIdsUser)
      store.dispatch(fetchUsers(usersInfo))
    }

    store.dispatch(fetchDialogs(dialogs))
    return store.getState().dialogs
  }

  async getMessages(dialog) {
   
    const isAlredyUpdate = this.getMessagesByDialogId(dialog.id)
    let amountMessages = null

    // If the first entry into the chat
    if (!dialog.isAlreadyMessageFetch || dialog.unread_messages_count > 0 && !dialog.isAlreadyMessageFetch) {
      const historyFromServer = await ConnectyCube.chat.message.list({
        chat_dialog_id: dialog.id,
        sort_desc: 'date_sent'
      })
      const messages = []
      historyFromServer.items.forEach(elem => {
        if (!elem.group_chat_alert_type) {
          messages.push(new Message(elem, this.currentUser.id))
        }
      })

      const newObj = Object.assign(dialog, { isAlreadyMessageFetch: true })
      this.updateDialogsUnreadMessagesCount(newObj)
      store.dispatch(fetchMessages(dialog.id, messages))
      amountMessages = messages.length
    } else {
      // If the second entry into the chat
      if (dialog.unread_messages_count > 0) {
        const messages = this.getMessagesByDialogId(dialog.id);
        const firstUnreadMsg = messages[dialog.unread_messages_count - 1]
        this.readAllMessages(dialog.id)
        await this.sendReadStatus(firstUnreadMsg.id, firstUnreadMsg.sender_id, firstUnreadMsg.dialog_id)
        this.updateDialogsUnreadMessagesCount(dialog)
      }
      amountMessages = isAlredyUpdate.length
    }
    return amountMessages
  }

  // Message loading if more than 100
  getMoreMessages = async (dialog) => {
    const currentMessages = this.getMessagesByDialogId(dialog.id)
    const lastMessageDate = currentMessages[0]
    const updateObj = Object.assign(dialog, { last_messages_for_fetch: lastMessageDate.date_sent })

    const filter = {
      chat_dialog_id: dialog.id,
      date_sent: { lt: lastMessageDate.date_sent },
      sort_desc: 'date_sent'
    }

    const moreHistoryFromServer = await ConnectyCube.chat.message.list(filter)

    const messages = []
    moreHistoryFromServer.items.forEach(elem => {
      if (!elem.group_chat_alert_type) {
        messages.push(new Message(elem, this.currentUser.id))
      }
    })

    store.dispatch(updateDialog(updateObj))
    const amountMessages = store.dispatch(lazyFetchMessages(dialog.id, messages))
    return amountMessages.history.length
  }

  async sendMessage(dialog, messageText, attachments = false, scrollToBottom) {

    const user = this.currentUser
    const text = messageText.trim()
    const date = Math.floor(Date.now() / 1000)
    const recipient_id = dialog.type === DIALOG_TYPE.PRIVATE ? dialog.occupants_ids.find(elem => elem != user.id)
      : dialog.xmpp_room_jid

    let extra_msg = '';
    let sender_email = '';
    let receiver_email = '';
    await this.getUsersList(user.id)
    .then((response) => {
      sender_email = response[0].login;
    });
    await this.getUsersList(recipient_id)
    .then((response) => {
      receiver_email = response[0].login;
    });
    console.log(dialog, "dialogdialog");
    console.log(sender_email, "sender_emailsender_email");
    console.log(receiver_email, "receiver_emailreceiver_email");
    await fetch(constVal.base_url+'/static_message.php?sender='+user.id+'&reciver='+recipient_id+'&sender_email='+sender_email+'&reciver_email='+receiver_email)
      .then(res => res.json())
      .then((responseJson) => {
        console.log(responseJson, "push Notification");
        if(responseJson.trim() != ''  && responseJson.trim()!= null && responseJson.trim() != undefined)
          extra_msg = '<br /><span class="extra_msg">'+responseJson.trim()+'</span>';
      }).catch((error) => {
        console.error(error);
    });

    // console.log(extra_msg, "extra_msg")
    let msg = {
      type: dialog.xmpp_type,
      body: text+extra_msg,
      extension: {
        save_to_history: 1,
        dialog_id: dialog.id,
        sender_id: user.id,
        date_sent: date,
      },
      // markable: 1
    }

    msg.id = this.messageUniqueId

    // If send message as Attachment
    if (attachments) {
      return this.sendMessageAsAttachment(dialog, recipient_id, msg, attachments, scrollToBottom)
    }

    const message = new FakeMessage(msg)

    const newObjFreez = Object.freeze(message)

    await store.dispatch(pushMessage(newObjFreez, dialog.id))
    scrollToBottom()
    ConnectyCube.chat.send(recipient_id, msg)
    store.dispatch(sortDialogs(newObjFreez))
  }


  sendMsgChatAlertOnCreate = async (dialog, message, alertType) => {
    const date = Math.floor(Date.now() / 1000)
    const recipient_id = dialog.type === DIALOG_TYPE.PRIVATE ? dialog.occupants_ids.find(elem => elem != this.currentUser.id)
      : dialog.xmpp_room_jid
    const messageExtensions = {
      date_sent: date,
      save_to_history: 1,
      dialog_id: dialog.id,
      group_chat_alert_type: alertType,
      sender_id: this.currentUser.id,
    }
    const msg = {
      type: !dialog.xmpp_room_jid ? 'chat' : 'groupchat',
      body: message,
      extension: messageExtensions,
    }
    ConnectyCube.chat.send(recipient_id, msg)
  }

  sendChatAlertOnCreate(dialog) {
    const message = 'Group is created'
    this.sendMsgChatAlertOnCreate(dialog, message, GROUP_CHAT_ALERT_TYPE.CREATE)
  }

  async sendMessageAsAttachment(dialog, recipient_id, msg, attachments, scrollToBottom) {
    //create fake data for render img
    const url = URL.createObjectURL(attachments.file)
    msg.extension.attachments = [{ url }]
    msg.body = 'Image attachment'
    const message = new FakeMessage(msg)
    await store.dispatch(pushMessage(message, dialog.id))
    scrollToBottom()

    // create real data for attachment
    const response = await this.uploadPhoto(attachments)
    const newObjAttach = preparationAttachment(response)
    msg.extension.attachments = [newObjAttach]
    await ConnectyCube.chat.send(recipient_id, msg)
    store.dispatch(sortDialogs(message))
    return
  }
  async createPrivateDialog_new(userId) {
    const dialogs = store.getState().dialogs
    let dialog = null

    dialogs.forEach(elem => {
      if (elem.type === DIALOG_TYPE.PRIVATE && elem.occupants_ids.find(elem => elem === userId)) {
        dialog = elem
      }
    })

    if (!dialog) {
      const params = {
        type: DIALOG_TYPE.PRIVATE,
        occupants_ids: userId,
      }

      const response = await ConnectyCube.chat.dialog.create(params)
      dialog = new Dialog(response)
      if (this.getUserFromReduxById(userId)) {
        store.dispatch(addNewDialog(dialog))
        return dialog
      } else {
        const usersInfo = await this.getUserFromServerById(userId)
        // usersInfo.user = new UserModel(usersInfo.user)
        store.dispatch(fetchUsers([usersInfo.user]))
        store.dispatch(addNewDialog(dialog))
        return dialog
      }
    }
      return dialog
  }


  async createPrivateDialog(userId) {
    const dialogs = store.getState().dialogs
    let dialog = null

    dialogs.forEach(elem => {
      if (elem.type === DIALOG_TYPE.PRIVATE && elem.occupants_ids.find(elem => elem === userId)) {
        dialog = elem
      }
    })

    if (!dialog) {
      const params = {
        type: DIALOG_TYPE.PRIVATE,
        occupants_ids: userId,
      }

      const response = await ConnectyCube.chat.dialog.create(params)
      dialog = new Dialog(response)
      if (this.getUserFromReduxById(userId)) {
        store.dispatch(addNewDialog(dialog))
        return dialog
      } else {
        const usersInfo = await this.getUserFromServerById(userId)
        usersInfo.user = new UserModel(usersInfo.user)
        store.dispatch(fetchUsers([usersInfo.user]))
        store.dispatch(addNewDialog(dialog))
        return dialog
      }
    }

    // If the user is already in the Redux
    if (this.getUserFromReduxById(userId)) {
      return dialog
    } else {
      const usersInfo = await this.getUserFromServerById(userId)
      usersInfo.user = new UserModel(usersInfo.user)
      store.dispatch(fetchUsers([usersInfo.user]))
      return dialog
    }
  }


  updateDialogsUnreadMessagesCount = (dialog) => {
    const updateObj = Object.assign(dialog, { unread_messages_count: 0 })
    store.dispatch(updateDialog(updateObj))
    return true
  }

  async createPublicDialog(occupants_ids, groupName, img) {
    const currentUser = this.currentUser
    occupants_ids.unshift(currentUser.id)
    const params = {
      type: DIALOG_TYPE.GROUP,
      occupants_ids,
      name: groupName,
    }
    const image = img ? await this.uploadPhoto(img) : null
    if (image) {
      params.photo = image.uid
    }
    const dialog = await ConnectyCube.chat.dialog.create(params)
    const newDialog = new Dialog(dialog)
    store.dispatch(addNewDialog(newDialog))
    return newDialog
  }

  async readAllMessages(dialogId) {
    return ConnectyCube.chat.message.update(null, {
      chat_dialog_id: dialogId,
      read: 1
    })
  }

  async readMessage(messageId, dialogId) {
    this.onReadStatus(messageId, dialogId)
    return ConnectyCube.chat.message.update(null, {
      chat_dialog_id: dialogId,
      read: 1
    })
  }

  async onMessageListener(senderId, msg) {
    const message = new Message(msg)
    const user = this.currentUser
    const dialog = this.getSelectedDialog()?.id

    // If group chat alet
    if (msg.extension.group_chat_alert_type) {
      const dialogsFromServer = await ConnectyCube.chat.dialog.list()
      const dialogs = dialogsFromServer.items.map(elem => {
        return new Dialog(elem)
      })
      store.dispatch(fetchDialogs(dialogs))
      return
    }

    if (senderId !== user.id) {
      if (dialog === message.dialog_id) {
        store.dispatch(sortDialogs(message))
        this.readMessage(message.id, message.dialog_id)
        this.sendReadStatus(msg.extension.message_id, msg.extension.sender_id, msg.dialog_id)
      } else {
        this.sendDeliveredStatus(msg.extension.message_id, msg.extension.sender_id, msg.dialog_id)
        store.dispatch(sortDialogs(message, true))
      }
      store.dispatch(pushMessage(message, message.dialog_id))
    }
  }

  // ConnectyCube listeners
  onSentMessageListener(failedMessage, msg) {
    console.warn('onSentMessageListener')
    if (failedMessage || msg.extension.group_chat_alert_type) {
      return
    }
    store.dispatch(updateMessages(msg.extension.dialog_id, msg.id, { send_state: STATUS_SENT }))
  }

  onDeliveredStatus(messageId, dialogId, userId) {
    console.warn('onDeliveredStatus', messageId)
    store.dispatch(updateMessages(dialogId, messageId, { send_state: STATUS_DELIVERED }))
  }

  onReadStatus(messageId, dialogId, userId) {
    console.warn('onReadStatus', messageId)
    store.dispatch(updateMessages(dialogId, messageId, { send_state: STATUS_READ }))
  }



  sendReadStatus(messageId, userId, dialogId) {
    ConnectyCube.chat.sendReadStatus({ messageId, userId, dialogId })
  }

  sendDeliveredStatus(messageId, userId, dialogId) {
    ConnectyCube.chat.sendDeliveredStatus({ messageId, userId, dialogId })
  }

  async getUsersList(ids) {
    const usersList = await ConnectyCube.users.get({
      per_page: 500,
      filter: {
        field: 'id', param: 'in', value: ids,
      },
    })

    return usersList.items.map(elem => {
      return new UserModel(elem.user)
    })
  }

  async getUserFromServerById(id) {
    return ConnectyCube.users.get(id)
  }

  setSelectDialog(dialog) {
    store.dispatch(selectedDialog(dialog))
  }

  getSelectedDialog() {
    return store.getState().selectedDialog
  }

  getDialogById(dialogId) {
    return store.getState().dialogs.find(elem => elem.id === dialogId)
  }

  getMessagesByDialogId(dialogId) {
    const result = store.getState().messages
    return result[dialogId]
  }

  async uploadPhoto(file) {
    return ConnectyCube.storage.createAndUpload(file)
  }

  get currentUser() {
    return store.getState().currentUser.user
  }

  getUserFromReduxById(id) {
    return store.getState().users[id]
  }

  get messageUniqueId() {
    return ConnectyCube.chat.helpers.getBsonObjectId()
  }
}


const chatService = new ChatService()

Object.freeze(chatService)

export default chatService

