import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { RecyclerListView, DataProvider } from "recyclerlistview/web"
import { connect } from 'react-redux'
import { DialogLayoutUtil } from '../../../../helpers/LayoutUtil'
import Loader from '../../../../helpers/loader/loader'
import lastDate from '../../../../helpers/lastDate'
import Avatar from '../../../../helpers/avatar/avatar'
import './dialogs.css'
import { Redirect } from "react-router-dom"
import * as constVal from '../../../../constant';
import ChatService from '../../../../services/chat-service'
import store from '../../../../store'
import ConnectyCube from 'connectycube'


class Dialog extends Component {
  scrollWidth = 0
  scrollHeight = 0
  listenerWindowSize = null
  timer = null
  allDialog = []

  constructor(props) {
    super(props)
    this.state = {
      isAlredy: false,
      dataProvider: new DataProvider((r1, r2) => {
        return r1 !== r2 || r1.unread_messages_count !== r2.unread_messages_count;
      }),
      layoutProvider: 0,
      count: 20,
      isLoader: true,
      search: '',
      chatopen:false,
      toggle: true, 
      showcontacts: false, 
      lastMessages :'',
      all_contacts : [], 
      previous_empty : '', 
      currentchat : '', 
    }
  }
  
  async componentDidMount() {
    let logginemail  = localStorage.getItem('LoggedinUser');
    if(logginemail == null || logginemail == 'null' || logginemail == ' ' || logginemail == '' || logginemail == undefined)
    {
      window.location.href=constVal.base_url2+"/login-form.html";
    }
    window.addEventListener('resize', this.handleResize)
    this.scrollWidth = document.getElementById('dialog-info-container').clientWidth
    this.scrollHeight = document.getElementById('dialog-info-container').clientHeight
    let  temp_id = [];  
    await ChatService.fetchDialogsFromServer().then( async (dialogs) => {
        let testdialogs = dialogs;
       await Promise.all(testdialogs.map(async (dig, index) => {
          if(dialogs[index].last_message === '' && this.state.currentchat != dialogs[index].id)
          {
              temp_id.push(index);
          }
      }));
      temp_id.sort((a, b) => b - a);
      await Promise.all(temp_id.map(async (dig, index) => {
         // dialogs.splice(dig, 1); 
      }));

      this.allDialog = dialogs
      this.setState({
        isAlredy: true,
        isLoader: false,
        layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(this.scrollWidth),
        dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
      });
      let selected = localStorage.getItem('selected');
      
      setTimeout(function() {
        if(this.state.chatopen == false && selected !== null && selected !== 'null' && selected !== ' '&& selected !== '' && selected !== undefined)
        {
          this.setState({chatopen :  true});
            this.goToChatExternal(selected);
        }
      }.bind(this), 8000)
    });
    

    

  }

  componentDidUpdate(prevProps) {
    const { dialogs } = this.props
    if (this.props.dialogs !== prevProps.dialogs
    ) {
      this.allDialog = dialogs
      this.setState({
        layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(this.scrollWidth),
        dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
      })
    }    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.scrollWidth = document.getElementById('dialog-info-container').clientWidth
    this.scrollHeight = document.getElementById('dialog-info-container').clientHeight
    if (!this.timer) {
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.timer = null
        this.setState({
          isAlredy: true,
          layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(this.scrollWidth)
        })
      }, 500)
    }
  }
  promisedSetState = () => {
    let email = localStorage.getItem('LoggedinUser');
    let user_type =  ''; 
    if(localStorage.getItem('UserType') == "1")
    {
      user_type = 'Instructor';
    }
    else if(localStorage.getItem('UserType') == "2")
    {
      user_type = 'Student';
    }
    if(user_type == 'Instructor' || user_type == 'instructor')
    {
      fetch(constVal.base_url+'/staff_data.php?emp_id='+email+'&deviceType=web')
      .then(res => res.json())
      .then((responseJson) => {
          
          if(responseJson.length>0)
          {
            this.setState({emp_id : responseJson[0].employee_id});
              fetch(constVal.base_url+'/instructor_student_list.php?emp_id='+responseJson[0].employee_id+'&deviceType=web')
              .then(res1 => res1.json())
              .then((responseJson1) => {  
                    this.searchUsers(responseJson1.response_data);       
              }).catch((error1) => {
                console.error(error1);
              });
        }
        }).catch((error) => {
          console.error(error);
        });
    }
    else if(user_type == 'Student' || user_type == 'student')
    {
      fetch(constVal.base_url+'/student_instructor_list.php?email='+email+'&deviceType=web')
      .then(res => res.json())
      .then((responseJson) => {
          this.searchUsers(responseJson.response_data);        
        }).catch((error) => {
          console.error(error);
        });
    }
    else 
    {
      this.setState({isLoader : false});
      const { router } = this.props
      router('/home')
    }
  }


  searchUsers = (userdetails) => {
    let dialogs = []
    userdetails.forEach(elem => {
       dialogs.push(elem)
    });
    console.log(dialogs, "dialogsdialogs")
    this.setState({
      isLoader : false,
      all_contacts : dialogs, 
      dataProvider: this.state.dataProvider.cloneWithRows(dialogs)
    });
  }



  changeSearch = (event) => {
    console.log(this.allDialog, "this.allDialog");
    let dialogs = []
    if (event.target.value === '') {
      dialogs = this.allDialog
    } else {
      this.allDialog.forEach(elem => {
        const str = elem.name.toUpperCase().includes(event.target.value.toUpperCase())
        str && dialogs.push(elem)
      })
    }
    this.setState({
      search: event.target.value,
      dataProvider: this.state.dataProvider.cloneWithRows(dialogs)
    })
  }
  goToChatExternal = async (id) => {
    localStorage.setItem('selected', '');
    console.log(id,"idddd123");
      const { router } = this.props
      const dialog = ChatService.getDialogById(id);
      console.log(dialog, "gitto external chat ")
      ChatService.setSelectDialog(dialog);
      router('/home/chat')    
  }
  goToChat = (item) => {
    
      const { router } = this.props
      ChatService.setSelectDialog(item);
      this.setState({currentchat :item.id })
      this.diaplayContacts();
      router('/home/chat')
  }

  createNewDialog = () => {
    const { router } = this.props;
    this.setState({isLoader : true});
    this.setState({showcontacts :  true});
    // const { dialogs } = this.props
    // console.log(dialogs, "dialogs");
    this.promisedSetState();
    // router('/home/contacts')
  }
  diaplayContacts = () => {
    const { router } = this.props;
    this.componentDidMount();
    this.setState({showcontacts :  false});
    router('/home');
  }
  _renderDialog = (type, item) => {
    return (
      <li className="dialog-li" onClick={() => this.goToChat(item)}>
        <div className="dialog-wrap-avatar">
          <Avatar photo={item.photo} name={item.name} size={50} />
        </div>
        <div className="dialog-wrap-block">
          <div className="dialog-wrap-block-left" style={{width: lastDate({
              lastDate: item.last_message_date_sent,
              lastMessage: item.last_message,
              updatedDate: item.updated_date,
            }).indexOf(NaN) != -1 ? "87%" :  "67%"}}>
            <h5>{item.name}</h5>
            {item.last_message === '' ? 
             <span>No messages yet</span> 
            : <span className="test">{item.last_message != '' && item.last_message != null && item.last_message != undefined ? item.last_message.replace(/<(.|\n)*?>/g, ' ').replace(/&nbsp;/g,' ') : item.last_message}</span>}
            {/* <span className="test">{item.last_message}</span>} */}
            {/* <span>{item.last_message === '' ? "No messages yet" : item.last_message}</span> */}
          </div>
          <div className="dialog-wrap-block-right chat_datetime">
          {item.last_message === '' ? null : <p>{lastDate({
              lastDate: item.last_message_date_sent,
              lastMessage: item.last_message,
              updatedDate: item.updated_date,
            }).indexOf(NaN) != -1 ? null : lastDate({
              lastDate: item.last_message_date_sent,
              lastMessage: item.last_message,
              updatedDate: item.updated_date,
            })}</p> 
          }
            {item.unread_messages_count > 0 &&
              <span>{item.unread_messages_count}</span>
            }
          </div>
        </div>
      </li>
    )
  }

  render() {
    const { isLoader, dataProvider, layoutProvider, search } = this.state
    return (
      <div className="dialog-container"  style={{flexDirection:"row",}}>
        <div style={{flexDirection:"row",}}>
            <button onClick={this.createNewDialog} className="contactsshown" >
              <FontAwesomeIcon icon={faUserAlt} color={'#d2d2d2'} />
                <span> {"         "}New Message</span>
            </button>
            {this.state.showcontacts == true ? 
            <button onClick={this.diaplayContacts} className="dialog-oldmessage">
              <FontAwesomeIcon icon={faWindowClose} color={'#d2d2d2'} />
            </button>
            :   
            <button onClick={this.createNewDialog} className="dialog-oldmessage">
              <FontAwesomeIcon icon={faWindowClose}  style={{color:"#4f4f4f"}} color={'#d2d2d2'} />
            </button> }
        </div>
        {/* <div className="dialog-input-search">
          <div className="dialog-input-search-icon">
            <FontAwesomeIcon icon={faSearch} color={'#d2d2d2'} />
          </div>
          <input
            className="input-field"
            type="text"
            value={search}
            onChange={this.changeSearch}
            required
            placeholder="Search chats..."
            name="search" />
        </div> */}
        <div className="dialog-info-container" id="dialog-info-container">
          {isLoader ?
            (
              <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                <Loader />
              </div>
            ) : dataProvider.length === 0 ?
              <div className="dialog-no-chats">
                <h3>No chats yet</h3>
              </div> : this.state.isAlredy ?
                dataProvider._data.length > 0 ?
                  <ul className="dialog-ul">
                    {
                      <RecyclerListView
                        style={{ width: this.scrollWidth, height: this.scrollHeight }}
                        dataProvider={dataProvider}
                        layoutProvider={layoutProvider}
                        rowRenderer={this._renderDialog}
                      />
                    }
                  </ul> : <div className="dialog-no-chats">
                    <h3>No results</h3>
                  </div> : null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ dialogs }) => ({
  dialogs
})

export default connect(mapStateToProps)(Dialog)
