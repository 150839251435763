import React, { PureComponent } from 'react'
import Avatar from '../../../helpers/avatar/avatar'
import ChatInput from './chatInput/chatInput'
import { connect } from 'react-redux'
import ChatService from '../../../services/chat-service'
import UsersService from '../../../services/users-service'
import { RecyclerListView, DataProvider } from "recyclerlistview/web"
import { ChatLayoutUtil } from '../../../helpers/LayoutUtil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import store from '../../../store'
import Loader from '../../../helpers/loader/loader'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Message from './message/message'
import * as constVal from '../../../constant';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import './chat.css'

class Chat extends PureComponent {
  scrollWidth = 0
  scrollHeight = 0
  listenerWindowSize = null
  timer = null
  isFetchingMsg = false
  messagesListRef = null
  listenerLazyLoad = false
  needToGetMoreMessage = null


  recycler_Y = 0
  contentHeight = 0
  contentNewOffset = 0

  constructor(props) {
    super(props)
    this.state = {
      isAlredy: true,
      selectedName:'', 
      profile_photo : '', 
      selected_dialog : '', 
      user_details:'',
      dataProvider: new DataProvider((r1, r2) => {
        return r1 !== r2 || r1.send_state !== r2.send_state
      }),
      layoutProvider: []
    }
    this.currentUserInfo = store.getState().currentUser.user
  }


  lazyLoadMessages = (elem, y) => {
    this.recycler_Y = y
    this.contentHeight = elem.nativeEvent.contentSize.height
    if (this.listenerLazyLoad && this.needToGetMoreMessage && y < 2000) {
      this.listenerLazyLoad = false
      ChatService.getMoreMessages(this.props.selectedDialog)
        .then(amountMessages => {
          amountMessages === 100 ? this.needToGetMoreMessage = true : this.needToGetMoreMessage = false
          this.listenerLazyLoad = true
        })
    }
  }

  getDialogInfo = async () => {
    let dialog_id = this.props.selectedDialog.id;
    console.log(dialog_id,"dialog_id12344");
    if(this.props.selectedDialog.contacts && this.props.selectedDialog.contacts !== undefined && this.props.selectedDialog.contacts == true)
    {
      console.log(this.props.selectedDialog, "this.props.selectedDialogthis.props.selectedDialog")
      if(this.state.selected_dialog == null || this.state.selected_dialog == '' ||  this.state.selected_dialog == undefined)
      {
        await ChatService.createPrivateDialog(this.props.selectedDialog.id)
        .catch(e => alert(`Error.\n\n${JSON.stringify(e)}`))
        .then((newDialog) => {
          dialog_id =newDialog.id;
          this.setState({selected_dialog : dialog_id});
        });
      }
      else 
      {
        dialog_id =this.state.selected_dialog;
      }
     
    }
    const dialog = ChatService.getDialogById(dialog_id)

    
    this.setState({selectedName: this.props.selectedDialog.name, profile_photo: this.props.selectedDialog.photo});
    // get info about occupants
    // await UsersService.getOccupants(dialog.occupants_ids)

    ChatService.getMessages(dialog)
      .catch(e => alert(`Error.\n\n${JSON.stringify(e)}`))
      .then(amountMessages => {
        amountMessages === 100 ? this.needToGetMoreMessage = true : this.needToGetMoreMessage = false
        if(dialog && dialog.id)
        {
          this.setState({
            isFetchingMsg: true,
            layoutProvider: ChatLayoutUtil.getChatLayoutProvider({
              width: this.scrollWidth,
              dialogId: dialog.id,
              currentUserId: this.currentUserInfo.id
            }),
            dataProvider: this.state.dataProvider.cloneWithRows(this.props.messages[dialog.id])
          })
        }
        this.scrollToBottom()
        this.listenerLazyLoad = true
      })
  }

  getUserDetails  = async () => {
    
    console.log(this.props.selectedDialog,"this.props.selectedDialog");
    var receiver_ocupt_id='';
    var sender_ocupt_id =this.currentUserInfo.id;
    var occupt_ids=this.props.selectedDialog.occupants_ids;
    if(occupt_ids[0]==sender_ocupt_id)
    {
        receiver_ocupt_id=occupt_ids[1];
    }
    else if(occupt_ids[1]==sender_ocupt_id)
    {
        receiver_ocupt_id=occupt_ids[0];
    }

    if(receiver_ocupt_id!='')
    {
      
      await fetch(constVal.base_url+'/get_user_info.php?sender_email='+localStorage.getItem('LoggedinUser')+'&receiver_conecty_id='+receiver_ocupt_id+'&sender_contecty_id='+sender_ocupt_id+'&deviceType=web')
        .then(res => res.json())
        .then((responseJson) => {
          this.setState({user_details:responseJson});
          console.log(this.state.user_details,"user inforesponseJson");
        }).catch((error) => {
          console.error(error);
      });
      
    }
    console.log(sender_ocupt_id,"sender_ocupt_id");
    console.log(occupt_ids,"occupt_ids");
    console.log(receiver_ocupt_id,"receiver_ocupt_id");
  }




  componentDidMount() {

    let logginemail  = localStorage.getItem('LoggedinUser');
    if(logginemail == null || logginemail == 'null' || logginemail == ' ' || logginemail == '' || logginemail == undefined)
    {
      window.location.href=constVal.base_url2+"/login-form.html";
    }
    window.addEventListener('resize', this.handleResize)
    this.scrollWidth = document.getElementById('chat-body').clientWidth
    this.scrollHeight = document.getElementById('chat-body').clientHeight
    this.getDialogInfo()

    this.getUserDetails();


  }

  async componentDidUpdate(prewProps) {
    let dialog_id = this.props.selectedDialog.id;
  
    if(this.props.selectedDialog.contacts && this.props.selectedDialog.contacts !== undefined && this.props.selectedDialog.contacts == true)
    {
      console.log(ChatService.getSelectedDialog(), "ChatServiceChatService");
      if(this.state.selected_dialog == null || this.state.selected_dialog == '' ||  this.state.selected_dialog == undefined)
      { 
       await fetch(constVal.base_url+'/get_user_dialog.php?sender='+localStorage.getItem('LoggedinUser')+'&receiver='+this.props.selectedDialog.login+'&deviceType=web')
        .then(res => res.json())
        .then((responseJson) => {
          dialog_id =responseJson;
        }).catch((error) => {
          console.error(error);
      });
        if(dialog_id == '' || dialog_id == null || dialog_id == undefined)
        {
          await ChatService.createPrivateDialog(this.props.selectedDialog.id)
           .catch(e => alert(`Error.\n\n${JSON.stringify(e)}`))
          .then((newDialog) => {
            dialog_id =newDialog.id;
            this.setState({selected_dialog : dialog_id});
          });
        }
     
      }
      else 
      {
        dialog_id = this.state.selected_dialog;
      }
    }
    
    const dialog = ChatService.getDialogById(dialog_id)
    
    

    if (prewProps.messages[dialog.id] &&
      prewProps.messages[dialog.id] !== this.props.messages[dialog.id]
    ) {
      console.log('{chat} prew props', prewProps.messages[dialog.id])
      console.log('{chat} this props', this.props.messages[dialog.id])

      if (this.props.messages[dialog.id].length) {
        this.setState({
          dataProvider: this.state.dataProvider.cloneWithRows(this.props.messages[dialog.id])
        }, () => { this.updateScrollPosition() }
        )
      }
    }
  }

  updateScrollPosition = () => {
    setTimeout(() => {
      if(document.getElementById('chat-body').children[0] && document.getElementById('chat-body').children[0].children[0] && document.getElementById('chat-body').children[0].children[0].children[0] && document.getElementById('chat-body').children[0].children[0].children[0].style && document.getElementById('chat-body').children[0].children[0].children[0].style.height)
      {
        const getElement = document.getElementById('chat-body').children[0].children[0].children[0].style.height
        const fullScrollHeight = getElement.slice(0, getElement.length - 2)
        const newOffset = this.recycler_Y + (fullScrollHeight - this.contentHeight)
        this.messagesListRef.scrollToOffset(0, newOffset)
      }
    }, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.scrollWidth = document.getElementById('chat-body').clientWidth
    this.scrollHeight = document.getElementById('chat-body').clientHeight
    if (!this.timer) {
      const dialog = ChatService.getDialogById(this.props.selectedDialog.id)
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.timer = null
        if(dialog && dialog.id)
        {
          this.setState({
            isAlredy: true,
            layoutProvider: ChatLayoutUtil.getChatLayoutProvider({
              width: this.scrollWidth,
              dialogId: dialog.id,
              currentUserId: this.currentUserInfo.id
            })
          })
        }
      }, 500)
    }
  }

  sendMessageCallback = async (messageText, img) => {
    let dialog_id = this.props.selectedDialog.id;
    if(this.props.selectedDialog.contacts && this.props.selectedDialog.contacts !== undefined && this.props.selectedDialog.contacts == true)
    {
      if(this.state.selected_dialog == null || this.state.selected_dialog == '' ||  this.state.selected_dialog == undefined)
      {
        await ChatService.createPrivateDialog(this.props.selectedDialog.id)
            .then((newDialog) => {
              dialog_id =newDialog.id;
              this.setState({selected_dialog : dialog_id});
              
            });
      }
      else 
      {
        dialog_id =this.state.selected_dialog;
      }
    }
    let email = localStorage.getItem('LoggedinUser');
    const dialog = ChatService.getDialogById(dialog_id)
    if (messageText.length <= 0 && !img) return
    var reciverid_new  = 0;
    var message_text = messageText;
    if(messageText.length <=0 )
      message_text = "You have received an attachement"
    for(var j=0; j<dialog.occupants_ids.length; j++)
    {
     var reciverid = dialog.occupants_ids[j];
      ChatService.getUsersList(reciverid)
      .then((response) => {
        console.log(response, "getuser list response");
        if(response !=='' && response[0] !=='' && response[0] !==undefined && response[0].login !=='' && response[0].login !== undefined)
        {
          if(response[0].login !== email )
          {
          
            if(j==1)
               reciverid_new = dialog.occupants_ids[1];
            else if(j==2)
               reciverid_new = dialog.occupants_ids[0];
               
                fetch(constVal.base_url+'/push_notification.php?sender_email='+btoa(email)+'&receiver_email='+btoa(response[0].login)+'&chat_id='+reciverid+'&message='+message_text+'&deviceType=web')
                  .then(res => res.json())
                  .then((responseJson) => {
                    console.log(responseJson, "push Notification");
                  }).catch((error) => {
                    console.error(error);
                });



          }
        }
      });
    }
    await ChatService.sendMessage(dialog, messageText, img, this.scrollToBottom);
  }

  goToSplashPage = () => {
    const { router } = this.props
    router('/home')
  }

  _renderMessage = (type, item) => {
    const { users } = this.props
    // 1 - current sender & 2 - other sender
    const whoIsSender = this.currentUserInfo.id === item.sender_id ? 1 : 2
    const participantInfo = whoIsSender === 2 ? users[item.sender_id] : null
    let notRenderAvatar = null

    if (type > 0 && whoIsSender !== 1 &&
      +this.state.dataProvider._data[type - 1].sender_id !== +item.sender_id) {
      notRenderAvatar = true
    }

    return (
      <Message
        whoIsSender={whoIsSender}
        message={item}
        participantInfo={participantInfo}
        notRenderAvatar={notRenderAvatar}
        widthScroll={this.scrollWidth}
      />
    )
  }

  getDialogById = () => {
    let dialog_id = this.props.selectedDialog.id;
    
    return ChatService.getDialogById(this.props.selectedDialog)
  }

  scrollToBottom = () => {
    if (this.messagesListRef) {
      this.messagesListRef.scrollToIndex(this.state.dataProvider._data.length - 1, false)
    }
  }

  render() {
    const { dataProvider, layoutProvider, isAlredy, isFetchingMsg } = this.state
    const { selectedDialog } = this.props
    let currentDialog
    let number;

    if (selectedDialog) {
      // currentDialog = this.getDialogById()
    }
    if(this.state.user_details!=''){
      if(this.state.user_details.is_manager=='1' && (this.state.user_details.reciever_user_type=='Instructor' || this.state.user_details.reciever_user_type=='Student') && this.state.user_details.receiver_mob!=undefined && this.state.user_details.receiver_mob!='')
      {
        let phone_str_rep=this.state.user_details.receiver_mob.replace(/[^0-9]+/g, "");
        if(phone_str_rep.trim().length >= 6 && phone_str_rep.trim().length < 10){
          console.log(phone_str_rep,"phone_str_rep");
          var first = phone_str_rep.trim().substr(0, 3);
          var second = phone_str_rep.trim().substr(3, 3);
          var third = phone_str_rep.trim().substr(6, 4);
          let number =  '('+first+') '+second+'-'+third;
          console.log(number,"number");
        }
        else if(phone_str_rep.trim().length >= 10){
          console.log('one4');
          phone_str_rep = phone_str_rep.trim().substr(0,10);
          var first = phone_str_rep.trim().substr(0, 3);
          var second = phone_str_rep.trim().substr(3, 3);
          var third = phone_str_rep.trim().substr(6, 4);
          number = '('+first+') '+second+'-'+third;
          console.log(number,"number");
        } 
      }

  }


    return (

      <div className="chat-container" >
        <header style={{padding:0, paddingLeft: window.innerWidth < 768 ? 0 : 15}}>
          {window.innerWidth < 768 &&
            <button onClick={this.goToSplashPage} style={{marginRight: 25,width: 20}}>
              <FontAwesomeIcon icon={faArrowLeft} color={'#212529'} />
            </button>
          }

          <Avatar photo={this.state.profile_photo} name={this.state.selectedName !== null && this.state.selectedName !== '' && this.state.selectedName !== undefined ? this.state.selectedName : "    "} size={50} />
          <h3>{this.state.selectedName !== null && this.state.selectedName !== '' && this.state.selectedName !== undefined ? this.state.selectedName : "     "}</h3>

          {this.state.user_details!=''?this.state.user_details.is_manager=='1' && this.state.user_details.reciever_user_type=='Instructor'?(<h3><a style={{textDecoration:'none'}} target="_blank" href={constVal.base_url2+"administrator/index.php?option=com_sobipro&task=entry.edit&sid="+this.state.user_details.receiver_sid+"&pid=1"}>Profile</a></h3>) : this.state.user_details.is_manager=='1' && this.state.user_details.reciever_user_type=='Student'?(<h3><a style={{textDecoration:'none'}} target="_blank" href={"https://account6.securedata-trans.com/ap/ap_admin_v2/customer_profile_v2.php?customer_id="+this.state.user_details.receiver_sid}>Profile</a></h3>):'':''}

          

          {this.state.user_details!=''?this.state.user_details.is_manager=='1' && (this.state.user_details.reciever_user_type=='Instructor' || this.state.user_details.reciever_user_type=='Student') && this.state.user_details.receiver_mail_id!=undefined && this.state.user_details.receiver_mail_id!=''?(<h3><a style={{textDecoration:'none',color:'#555'}} target="_blank" href={"mailto:"+this.state.user_details.receiver_mail_id} >{this.state.user_details.receiver_mail_id}</a></h3>):'':''}

          {this.state.user_details!=''?this.state.user_details.is_manager=='1' && (this.state.user_details.reciever_user_type=='Instructor' || this.state.user_details.reciever_user_type=='Student') && this.state.user_details.receiver_mob!=undefined && this.state.user_details.receiver_mob!=''?(<h3><a style={{textDecoration:'none',color:'#555'}} target="_blank" href={"tel:"+this.state.user_details.receiver_mob}>{number}</a></h3>):'':''}
          
        </header>
        <div className="chat-body" id="chat-body">
          {isAlredy && isFetchingMsg ?
            dataProvider && dataProvider._data.length > 0 &&
            <>     
              <RecyclerListView
                style={{
                  width: this.scrollWidth,
                  height: this.scrollHeight,
                }}
                ref={ref => this.messagesListRef = ref}
                dataProvider={dataProvider}
                layoutProvider={layoutProvider}
                rowRenderer={this._renderMessage}
                onScroll={(elem, x, y) => {
                  this.lazyLoadMessages(elem, y)
                }}
              />
            </> : <Loader />
          }
        </div>
        <ChatInput sendMessageCallback={this.sendMessageCallback} />
      </div>
    )
  }
}

const mapStateToProps = ({ selectedDialog, messages, users }) => ({
  selectedDialog,
  messages,
  users
})

export default connect(mapStateToProps)(Chat)
